<template lang="pug">
	article#listagem
		.wrapper
			h2(v-html="pagina.h2")
			p(v-if="pagina.subtitulo !== false").sub {{pagina.subtitulo}}

			ul.materias
				li(v-for="conteudo, index in conteudos").materia
					router-link(:to="getRoute(conteudo.slug)")
						.img
							img(:src="conteudo.thumb", :alt="conteudo.titulo")
						.description
							h4(v-html="conteudo.titulo")
							span {{ conteudo.data_publicacao }}
							p(v-html="conteudo.resumo")

			button(v-if="pagination.total > pagination.page", @click="loadConteudos(pagination.page + 1)") Carregar mais
</template>

<script>
import qs from 'qs'

export default {
	name: 'section-listagem',
	props: {
		categoria: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			conteudos: [],
			pagination: {
				total: 1,
				page: 1,
			}
		}
	},
	created() {
		this.loadConteudos()
	},
	watch: {
		'categoria': function () {
			this.loadConteudos(1)
		}
	},
	methods: {
		loadConteudos(page = 1) {
			let params = {
				limit: 4,
				page,
				categoria: this.isImprensa ? 'imprensa' : this.categoria,
			}
			this.$axios
				.get(`conteudo?${qs.stringify(params)}`)
				.then(response => {
					if (page == 1)
						this.conteudos = response.data.registros.data
					else
						response.data.registros.data.forEach(conteudo => this.conteudos.push(conteudo))
					this.pagination.total = response.data.registros.last_page
					this.pagination.page = page
				})
		},
		getRoute(slug) {
			let name = 'post-'
            if (this.isImprensa)
                name += 'imprensa'
            else
                name += 'blog'
            return {
                name,
                params: {
                    slug
                },
            }
		}
	},
	computed: {
        isImprensa() {
			return 'imprensa' === this.$route.name
		},
		pagina() {
			if (this.isImprensa)
				return {
					h2: 'QUANDO O ASSUNTO É INOVAÇÃO E TECNOLOGIA, A GT COMPANY SEMPRE É NOTÍCIA.',
					subtitulo: 'Acompanhe as mais recentes matérias publicadas pela imprensa.'
				}
			return {
				h2: 'INSPIRE-SE NESTAS TENDÊNCIAS, DADOS E CURIOSIDADES SOBRE A <br>GT COMPANY.',
				subtitulo: false
			}
		},
    },
}
</script>

<style lang="stylus" scoped src="./Listagem.styl"></style>
